








import {Vue, Component} from 'vue-property-decorator';
import UserTimeTrackerComponent from '@/components/User/TimeTracker.vue';
import ZoneTimeTracker from '@/components/User/ZoneTimeTracker.vue';
import UserClockinDayComponent from '@/components/User/ClockinDay.vue';

@Component({
    components: {UserTimeTrackerComponent, ZoneTimeTracker, UserClockinDayComponent},
})
export default class UserPointer extends Vue {}
